import React from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  width: 250,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  [theme.breakpoints.down('xs')]: {
    width: '100%'
  }
}))

const EsTimePicker = ({ label, onChange, value }) => {
  return (
    <StyledTimePicker
      label={label}
      minutesStep={1}
      onChange={onChange}
      renderInput={(params) => <TextField variant='standard' {...params} />}
      value={value}
    />
  )
}

export default EsTimePicker
