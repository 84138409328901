import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { trainersGetListAllQueryKey } from '../../../helpers/queryKeys'

const useTrainersWriteTrainerMutation = ({ resetLocalState }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({
      emailAddress,
      firstName,
      lastName,
      positionDepartment,
      trainerId
    }) => {
      const response = await axiosInstance.put('Trainers/WriteTrainer', {
        emailAddress,
        firstName,
        lastName,
        positionDepartment,
        trainerId
      })

      return {
        emailAddress,
        isNewRecord: trainerId === 0,
        firstName,
        lastName,
        positionDepartment,
        trainerId: response.data
      }
    },
    {
      onSuccess: (trainerInfo) => {
        enqueueSnackbar('Trainer has been saved', {
          variant: 'success'
        })

        resetLocalState()

        queryClient.setQueryData(
          trainersGetListAllQueryKey(),
          (prevTrainers) => {
            if (!trainerInfo.isNewRecord) {
              return [
                ...prevTrainers.map((trainer) => {
                  if (trainer.trainerId !== trainerInfo.trainerId)
                    return trainer
                  return { ...trainer, ...trainerInfo }
                })
              ]
            }

            return [...prevTrainers, { ...trainerInfo }]
          }
        )
      }
    }
  )
}

export default useTrainersWriteTrainerMutation
