import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { trainersGetListAllQueryKey } from '../../../helpers'

const useTrainersDeleteByIdMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (id) => {
      await axiosInstance.delete(`Trainers/DeleteById/${id}`)

      return id
    },
    {
      onSuccess: (id) => {
        enqueueSnackbar('Trainer has been deleted', {
          variant: 'success'
        })

        queryClient.setQueryData(
          trainersGetListAllQueryKey(),
          (prevTrainers) => {
            return [
              ...prevTrainers.filter((trainer) => trainer.trainerId !== id)
            ]
          }
        )
      }
    }
  )
}

export default useTrainersDeleteByIdMutation
