import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import {
  attendeesGetListBySessionIdSchoolIdQueryKey,
  sessionsGetSessionsListAfterDateQueryKey
} from '../../../helpers/queryKeys'

const useAttendeesWriteAttendeeMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({
      accountPassword,
      attendeeId,
      emailAddress,
      firstName,
      lastName,
      schoolId,
      sessionId
    }) => {
      const response = await axiosInstance.put('Attendees/WriteAttendee', {
        accountPassword,
        attendeeId,
        emailAddress,
        firstName,
        lastName,
        schoolId,
        sessionId
      })

      return {
        accountPassword,
        attendeeId: response.data,
        emailAddress,
        firstName,
        isNewRecord: attendeeId === 0,
        lastName,
        schoolId,
        sessionId
      }
    },
    {
      onSuccess: (attendee) => {
        enqueueSnackbar('Attendee has been saved', {
          variant: 'success'
        })

        // Update cached data for list of attendees by school id
        queryClient.setQueryData(
          attendeesGetListBySessionIdSchoolIdQueryKey({
            schoolId: attendee.schoolId,
            sessionId: attendee.sessionId
          }),
          (prevAttendees) => {
            if (attendee.isNewRecord) return [...prevAttendees, { ...attendee }]

            return [
              ...prevAttendees.map((prevAttendee) => {
                if (prevAttendee.attendeeId !== attendee.attendeeId)
                  return prevAttendee

                return {
                  ...prevAttendee,
                  emailAddress: attendee.emailAddress,
                  firstName: attendee.firstName,
                  lastName: attendee.lastName
                }
              })
            ]
          }
        )

        // Is this is a new record, need to update the count of attendees
        // for the training session
        if (attendee.isNewRecord) {
          queryClient.setQueryData(
            sessionsGetSessionsListAfterDateQueryKey(),
            (prevSessions) => {
              return prevSessions.map((prevSession) => {
                if (prevSession.session.sessionId !== attendee.sessionId) {
                  return prevSession
                }

                return {
                  ...prevSession,
                  listItems: [
                    ...prevSession.listItems.map((listItem) => {
                      if (listItem.schoolId !== attendee.schoolId)
                        return listItem
                      return {
                        ...listItem,
                        isFullAttendance: listItem.numberOfAttendees + 1 === 10,
                        numberOfAttendees: listItem.numberOfAttendees + 1,
                        requiresImport: true,
                        requiresNotification: true
                      }
                    })
                  ]
                }
              })
            }
          )
        }
      }
    }
  )
}

export default useAttendeesWriteAttendeeMutation
