import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  useAttendeesGetListBySessionIdSchoolIdQuery,
  useAttendeesWriteAttendeeMutation
} from '../../hooks'
import { FlexColumn, Loading } from '../shared'

import CurrentAttendees from './CurrentAttendees'

const INITIAL_ATTENDEE_STATE = {
  accountPassword: '',
  attendeeId: 0,
  emailAddress: '',
  firstName: '',
  lastName: ''
}

const AddAttendeesDrawer = ({ schoolId, sessionId, schoolName }) => {
  const { errors, handleSubmit, register } = useForm()

  const [attendeeInfo, setAttendeeInfo] = useState(INITIAL_ATTENDEE_STATE)
  const [open, setOpen] = useState(false)
  const [settingUpRecordToEdit, setSettingUpRecordToEdit] = useState(false)

  const {
    data: currentAttendees = [],
    isFetching: isFetchingAttendess,
    isLoading: isLoadingAttendess
  } = useAttendeesGetListBySessionIdSchoolIdQuery({
    enabled: open,
    schoolId,
    sessionId
  })

  const {
    isLoading: isSavingAttendee,
    isSuccess: hasSavedAttendee,
    mutate: saveAttendee
  } = useAttendeesWriteAttendeeMutation()

  useEffect(() => {
    if (hasSavedAttendee) {
      setAttendeeInfo(INITIAL_ATTENDEE_STATE)
    }
  }, [hasSavedAttendee])

  const closeDrawer = (reason) => {
    if (reason === 'backdropClick') {
      return
    }

    setAttendeeInfo(INITIAL_ATTENDEE_STATE)

    setOpen(false)
  }

  const onSubmit = (formData) => {
    saveAttendee({
      ...formData,
      accountPassword: '',
      attendeeId: attendeeInfo.attendeeId,
      schoolId,
      sessionId
    })
  }

  const setupAttendeeToEdit = (attendeeId) => {
    setSettingUpRecordToEdit(true)

    const attendee = currentAttendees.find(
      (attendee) => attendee.attendeeId === attendeeId
    )

    setAttendeeInfo(attendee)

    setTimeout(() => {
      setSettingUpRecordToEdit(false)
    }, '500')
  }

  if (isSavingAttendee) return <Loading text='Saving Attendee' />

  return (
    <>
      <Button
        color='neutralLight'
        onClick={() => setOpen(true)}
        size='small'
        sx={{ marginRight: 1 }}>
        Add Attendees
      </Button>
      <Drawer
        anchor='right'
        data-testid='add-attendee-drawer'
        open={open}
        onClose={(e, reason) => closeDrawer(reason)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn sx={{ padding: 2, height: '100%', minWidth: 400 }}>
            {!settingUpRecordToEdit ? (
              <>
                <Typography>{schoolName}</Typography>
                <TextField
                  data-testid='attendee-first-name'
                  defaultValue={attendeeInfo.firstName}
                  error={Boolean(errors.firstName)}
                  helperText={
                    errors.firstName ? errors.firstName.message : null
                  }
                  inputRef={register({
                    required: 'Please enter a First Name'
                  })}
                  label='First Name'
                  name='firstName'
                />
                <TextField
                  data-testid='attendee-last-name'
                  defaultValue={attendeeInfo.lastName}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName ? errors.lastName.message : null}
                  inputRef={register({
                    required: 'Please enter a Last Name'
                  })}
                  label='Last Name'
                  name='lastName'
                />
                <TextField
                  data-testid='attendee-email-address'
                  defaultValue={attendeeInfo.emailAddress}
                  error={Boolean(errors.emailAddress)}
                  helperText={
                    errors.emailAddress ? errors.emailAddress.message : null
                  }
                  inputRef={register({
                    required: 'Please enter an Email Address'
                  })}
                  label='Email Address'
                  name='emailAddress'
                />
              </>
            ) : (
              <Typography>Setting Up Attendee</Typography>
            )}
            <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 4 }}>
              <Button size='small' sx={{ marginRight: 1 }} type='submit'>
                Save
              </Button>
              <Button color='neutralLight' onClick={closeDrawer} size='small'>
                Close
              </Button>
            </Box>
            {isFetchingAttendess || isLoadingAttendess ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CircularProgress />
                <Typography>Loading Attendess</Typography>
              </Box>
            ) : (
              <CurrentAttendees
                currentAttendees={currentAttendees}
                setupAttendeeToEdit={setupAttendeeToEdit}
              />
            )}
          </FlexColumn>
        </form>
      </Drawer>
    </>
  )
}

export default AddAttendeesDrawer
