import { styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'

import { NAV_WIDTH } from '../constants'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: '#eee',
    width: '100vw',

    [theme.breakpoints.up('md')]: {
      width: NAV_WIDTH
    },

    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing()
    }
  }
}))
