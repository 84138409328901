import React from 'react'
import { ListItem } from '@mui/material'
import { Close, NoteAdd, Train } from '@mui/icons-material'

import logo from '../../images/es_logo_icon.png'
import { useLoggedInUser } from '../../context'
import { NavLink, StyledListItemIcon } from '../Nav'

import {
  StyledDrawer,
  StyledListItemText,
  StyledLogo
} from './styledComponents'

const Nav = ({ container, mobileMenuOpen, setMobileMenuOpen }) => {
  const { logout } = useLoggedInUser()

  const DrawerContent = (
    <>
      <StyledLogo>
        <img src={logo} alt='Enriching Students Logo' />
      </StyledLogo>
      <NavLink
        label='Training Sessions'
        onClick={() => {}}
        Icon={<Train />}
        to='/trainingSessions'
      />
      <NavLink
        label='Session Notes'
        onClick={() => {}}
        Icon={<NoteAdd />}
        to='/sessionNotes'
      />
      <ListItem button onClick={() => logout()}>
        <StyledListItemIcon>
          <Close />
        </StyledListItemIcon>
        <StyledListItemText primary='Logout' style={{ paddingLeft: 0 }} />
      </ListItem>
    </>
  )

  return (
    <>
      {/* For mobile devices */}
      <StyledDrawer
        sx={{ display: { xl: 'none' } }}
        container={container}
        variant='temporary'
        anchor='left'
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}>
        {DrawerContent}
      </StyledDrawer>
      {/* For larger devices */}
      <StyledDrawer
        variant='permanent'
        open
        sx={{ display: { xs: 'none', lg: 'block' } }}>
        {DrawerContent}
      </StyledDrawer>
    </>
  )
}

export default Nav
