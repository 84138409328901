import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetListAllSessionsQueryKey } from '../../../helpers'

const useSessionsGetListAllSessionsQuery = () => {
  return useQuery(
    sessionsGetListAllSessionsQueryKey(),
    async () => {
      const response = await axiosInstance.get('Sessions/GetListAllSessions')

      console.log(response, 'response')

      return response.data
    },
    {
      staleTime: 1000 * 60 * 5
    }
  )
}

export default useSessionsGetListAllSessionsQuery
