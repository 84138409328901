import { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'

import {
  useAttendeesRunImportsMutation,
  useSessionsDeleteByIdMutation,
  useSessionsNotifySessionAttendeesMutation
} from '../../hooks'
import { DeleteModal, Loading } from '../shared'

import AddSessionDrawer from './AddSessionDrawer'

const SessionActionsMenu = ({ sessionDate, sessionId, sessions, trainers }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openAddSession, setOpenAddSession] = useState(false)

  const open = Boolean(anchorEl)

  const { isLoading: isRunningImports, mutate: importSchoolData } =
    useAttendeesRunImportsMutation()

  const { isLoading: isDeletingSession, mutate: deleteSessionById } =
    useSessionsDeleteByIdMutation()

  const { isLoading: isNotifyingAttendees, mutate: notifyAttendees } =
    useSessionsNotifySessionAttendeesMutation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteSessionClick = () => {
    deleteSessionById(sessionId)
    handleClose()
  }

  const handleEditSessionClick = () => {
    handleClose()
    setOpenAddSession(true)
  }

  const handleImportSchoolData = () => {
    handleClose()
    importSchoolData(sessionId)
  }

  const handleNotifyAttendeesClick = () => {
    handleClose()
    notifyAttendees(sessionId)
  }

  if (isDeletingSession) return <Loading text='Deleting Session' />
  if (isNotifyingAttendees) return <Loading text='Sending Notifications' />
  if (isRunningImports) return <Loading text='Importing School Data' />

  return (
    <>
      <div>
        <Button
          data-testid={`session-action-button-for-${sessionDate}`}
          id='session-action-button'
          aria-controls={open ? 'session-action-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size='small'
          color='neutralLight'>
          Actions
        </Button>
        <Menu
          id='session-action-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'session-action-button'
          }}>
          <MenuItem onClick={handleEditSessionClick}>Edit Session</MenuItem>
          <MenuItem onClick={handleImportSchoolData}>
            Import School Data
          </MenuItem>
          <MenuItem onClick={handleNotifyAttendeesClick}>
            Notify Attendees
          </MenuItem>
          <DeleteModal
            confirmDeleteCallback={handleDeleteSessionClick}
            confirmDeleteLabel='Delete Session'
            confirmDeleteMessage='Are you sure you want to delete this session?'
            itemName={`Training Session - ${sessionDate}`}
            text='Remove Session'
            useMenuItem
          />
        </Menu>
      </div>
      {openAddSession && (
        <AddSessionDrawer
          open={openAddSession}
          setOpen={setOpenAddSession}
          sessionId={sessionId}
          sessions={sessions}
          trainers={trainers}
        />
      )}
    </>
  )
}

export default SessionActionsMenu
