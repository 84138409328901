import React from 'react'
import { Box, Typography } from '@mui/material'

import {
  useAttendeesDeleteByIdMutation,
  useAttendeesResendNotificationMutation
} from '../../hooks'

import AttendeeActionsMenu from './AttendeeActionsMenu'

const CurrentAttendeeItem = ({
  accountPassword,
  attendeeId,
  emailAddress,
  firstName,
  isDataImported,
  isLastItem,
  lastName,
  schoolId,
  sessionId,
  setupAttendeeToEdit
}) => {
  const { isLoading: isDeletingAttendee, mutate: deleteAttendee } =
    useAttendeesDeleteByIdMutation()

  const { isLoading: isNotifyingAttendee, mutate: notifyAttendee } =
    useAttendeesResendNotificationMutation()

  if (isDeletingAttendee) return 'Deleting...'
  if (isNotifyingAttendee) return 'Notifying...'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: !isLastItem && '1px solid #d9d9d9',
        paddingTop: 0.5,
        paddingBottom: 0.5
      }}>
      <Typography>
        {firstName} {lastName} - {emailAddress}
      </Typography>
      <AttendeeActionsMenu
        attendeeId={attendeeId}
        deleteAttendee={deleteAttendee}
        firstName={firstName}
        isDataImported={isDataImported}
        lastName={lastName}
        notifyAttendee={notifyAttendee}
        schoolId={schoolId}
        sessionId={sessionId}
        setupAttendeeToEdit={setupAttendeeToEdit}
      />
    </Box>
  )
}

export default CurrentAttendeeItem
