import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  convertDateTimeToUTC,
  sortArrayByFirstNameLastName,
  sortByCollatingField
} from '../../helpers'
import {
  useSchoolsGetListAllQuery,
  useSessionsWriteSessionWithSessionTrainersAndSchools
} from '../../hooks'
import { DatePicker, FlexColumn, List, Loading, TimePicker } from '../shared'

import SessionTrainerCheckbox from './SessionTrainerCheckbox'

const INITIAL_SESSION_STATE = {
  date: moment(),
  sessionId: 0,
  time: moment(),
  sessionSchools: [],
  sessionTrainers: [],
  zoomMeetingID: '',
  zoomMeetingPassCode: '',
  zoomMeetingURL: ''
}

const AddSessionDrawer = ({
  open,
  setOpen,
  sessionId = 0,
  sessions = [],
  trainers
}) => {
  const { errors, formState, handleSubmit, register } = useForm()

  const [loadingSession, setLoadingSession] = useState(false)
  const [sessionState, setSessionState] = useState(INITIAL_SESSION_STATE)
  const [trainersError, setTrainersError] = useState(false)

  const sortedTrainers = sortArrayByFirstNameLastName(trainers)

  const {
    data: schools = [],
    isFetching: isFetchingSchools,
    isLoading: isLoadingSchools
  } = useSchoolsGetListAllQuery()

  useEffect(() => {
    if (formState.isSubmitting && sessionState.sessionTrainers.length < 2) {
      setTrainersError(true)
    }
  }, [formState.isSubmitting, sessionState.sessionTrainers.length])

  useEffect(() => {
    if (sessionId !== 0) {
      setLoadingSession(true)
      const selectedSession = sessions.find(
        (session) => session.session.sessionId === sessionId
      )

      const { listItems, session, trainerIds } = selectedSession

      const {
        sessionDateTime,
        zoomMeetingID,
        zoomMeetingPassCode,
        zoomMeetingURL
      } = session

      const sessionDateTimeToLocal = moment.utc(sessionDateTime).local()

      let sessionSchools = []

      listItems.forEach((item) => sessionSchools.push(item.schoolId))

      const obj = {
        date: sessionDateTimeToLocal,
        sessionId,
        time: sessionDateTimeToLocal,
        sessionSchools,
        sessionTrainers: trainerIds,
        zoomMeetingID,
        zoomMeetingPassCode,
        zoomMeetingURL
      }

      setSessionState(obj)
      setLoadingSession(false)
    }
  }, [sessionId, sessions])

  const {
    isLoading: isSavingSession,
    mutate: saveSession,
    status: saveSessionStatus
  } = useSessionsWriteSessionWithSessionTrainersAndSchools({ schools })

  useEffect(() => {
    if (saveSessionStatus === 'success') {
      setLoadingSession(true)

      setSessionState(INITIAL_SESSION_STATE)

      setLoadingSession(false)
    }
  }, [saveSessionStatus])

  const closeDrawer = (reason) => {
    if (reason === 'backdropClick') {
      return
    }

    setOpen(false)
  }

  const onSubmit = (formData) => {
    if (sessionState.sessionTrainers.length < 2) {
      return
    }

    const sessionDateTime = convertDateTimeToUTC(
      sessionState.date,
      sessionState.time
    )

    const obj = {
      ...sessionState,
      ...formData,
      sessionDateTime
    }

    saveSession(obj)
  }

  const updateSelectedSchools = (event) => {
    const {
      target: { value }
    } = event
    setSessionState({
      ...sessionState,
      sessionSchools: typeof value === 'string' ? value.split(',') : value
    })
  }

  const updateSelectedTrainers = (e) => {
    const { checked, id } = e.target

    if (checked) {
      setSessionState({
        ...sessionState,
        sessionTrainers: [...sessionState.sessionTrainers, parseInt(id)]
      })

      setTrainersError(false)
    } else {
      setSessionState({
        ...sessionState,
        sessionTrainers: [
          ...sessionState.sessionTrainers.filter(
            (trainerId) => trainerId !== parseInt(id)
          )
        ]
      })
    }
  }

  if (loadingSession || isFetchingSchools || isLoadingSchools)
    return <Loading text='Loading Session' />
  if (isSavingSession) return <Loading text='Saving Session' />

  return (
    <Drawer
      anchor='right'
      data-testid='add-session-drawer'
      open={open}
      onClose={(e, reason) => closeDrawer(reason)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexColumn sx={{ padding: 2, height: '100%' }}>
          <Typography
            sx={{
              fontSize: 21,
              fontWeight: 300,
              paddingBottom: 2
            }}>
            Add Session
          </Typography>
          <DatePicker
            label='Session Date'
            onChange={(value) =>
              setSessionState({ ...sessionState, date: value })
            }
            value={sessionState.date}
          />
          <TimePicker
            label='Session Time'
            onChange={(value) =>
              setSessionState({ ...sessionState, time: value })
            }
            value={sessionState.time}
          />
          <TextField
            data-testid='zoom-meeting-url'
            defaultValue={sessionState.zoomMeetingURL}
            label='Zoom Meeting URL'
            name='zoomMeetingURL'
          />
          <TextField
            data-testid='zoom-meeting-id'
            defaultValue={sessionState.zoomMeetingID}
            error={Boolean(errors.zoomMeetingID)}
            helperText={
              errors.zoomMeetingID ? errors.zoomMeetingID.message : null
            }
            inputRef={register({
              required: 'Please enter a Zoom Meeting ID'
            })}
            label='Zoom Meeting ID'
            name='zoomMeetingID'
          />
          <TextField
            data-testid='zoom-meeting-passcode'
            defaultValue={sessionState.zoomMeetingPassCode}
            error={Boolean(errors.zoomMeetingPassCode)}
            helperText={
              errors.zoomMeetingPassCode
                ? errors.zoomMeetingPassCode.message
                : null
            }
            inputRef={register({
              required: 'Please enter a Zoom Meeting Passcode'
            })}
            label='Zoom Meeting Passcode'
            name='zoomMeetingPassCode'
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: trainersError && 1,
              border: trainersError && `1px solid red`,
              marginBottom: trainersError && 1
            }}>
            <Typography sx={{ color: trainersError && 'error.main' }}>
              Session Trainers (2 Required)
            </Typography>
            <List
              Component={SessionTrainerCheckbox}
              data={sortedTrainers}
              emptyArrayMessage='There are no trainers'
              keyValue='trainerId'
              onChange={updateSelectedTrainers}
              sessionTrainers={sessionState.sessionTrainers}
            />
          </Box>
          <FormControl sx={{ marginTop: 1 }}>
            <InputLabel
              id='schools-checkbox-label'
              sx={{ marginLeft: '-12px !important' }}>
              Schools (1 Required)
            </InputLabel>
            <Select
              labelId='schools-checkbox-label'
              id='schools-checkbox'
              multiple
              value={sessionState.sessionSchools}
              onChange={updateSelectedSchools}
              renderValue={(selectedSchoolIds) => {
                let selectedSchoolNames = []

                selectedSchoolIds.forEach((id) => {
                  schools.forEach((school) => {
                    if (school.schoolId === id) {
                      selectedSchoolNames.push(school.schoolName)
                    }
                  })
                })

                return selectedSchoolNames.join(', ')
              }}>
              {schools
                .sort(sortByCollatingField(true, 'schoolName'))
                .map((school) => (
                  <MenuItem key={school.schoolId} value={school.schoolId}>
                    <Checkbox
                      checked={
                        sessionState.sessionSchools.indexOf(school.schoolId) >
                        -1
                      }
                    />
                    <ListItemText primary={school.schoolName} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 4 }}>
            <Button size='small' sx={{ marginRight: 1 }} type='submit'>
              Save
            </Button>
            <Button color='neutralLight' onClick={closeDrawer} size='small'>
              Close
            </Button>
          </Box>
        </FlexColumn>
      </form>
    </Drawer>
  )
}

export default AddSessionDrawer
