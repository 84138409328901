import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledCheckox = styled(Checkbox)(({ theme }) => ({
  paddingLeft: theme.spacing()
}))

const LabeledCheckbox = ({
  checked,
  dataTestId,
  disabled,
  formControlSx,
  id,
  label,
  name,
  onChange,
  ...otherProps
}) => {
  return (
    <FormControlLabel
      sx={formControlSx}
      control={
        <StyledCheckox
          checked={checked}
          color='primary'
          disabled={disabled}
          id={id}
          inputProps={{ 'data-testid': dataTestId }}
          name={name}
          onChange={onChange}
          {...otherProps}
        />
      }
      label={label}
    />
  )
}

LabeledCheckbox.propTypes = {
  checked: PropTypes.bool,
  dataTestId: PropTypes.string,
  //label: PropTypes.string || PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default LabeledCheckbox
