import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'

const useAttendeesResendNotificationMutation = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (attendeeId) => {
      await axiosInstance.put(`Attendees/ResendNotification/${attendeeId}`)

      return
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Attendee has been notified', {
          variant: 'success'
        })
      }
    }
  )
}

export default useAttendeesResendNotificationMutation
