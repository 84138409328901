import React from "react";
import { styled } from "@mui/material/styles";

export const StyledListItemContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isLastItem",
})(({ isLastItem, theme }) => ({
  padding: theme.spacing(),

  borderBottom: !isLastItem && "1px solid #d9d9d9",

  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridAutoRows: "auto",

  alignItems: "center",
}));

const ListItemContainer = ({ children, dataTestId, isLastItem, sx }) => {
  return (
    <StyledListItemContainer
      data-testid={dataTestId}
      isLastItem={isLastItem}
      sx={sx}
    >
      {children}
    </StyledListItemContainer>
  );
};

export default ListItemContainer;
