import moment from 'moment'

export const convertDateTimeToUTC = (date, time) => {
  const formattedTime = moment(time).format()
  const formattedDate = moment(date).format()

  const dateFromFormattedDate = moment(formattedDate).format('YYYY-MM-DD')
  const timeFromFormattedTime = moment(formattedTime).local().format('HH:mm:ss')

  const templateDateTime = `${dateFromFormattedDate} ${timeFromFormattedTime}`

  return moment(templateDateTime).utc().format()
}
