import React, { useState } from 'react'
import moment from 'moment'
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'

import {
  useSchoolsGetListBySessionsIdQuery,
  useSessionsGetListAllSessionsQuery
} from '../../hooks'
import { Loading, PageTitle } from '../shared'

import { AddSessionNotes } from '../SessionNotes'

const SessionNotes = () => {
  const [selectedSessionId, setSelectedSessionId] = useState('')

  const {
    data: sessions = [],
    isFetching: isFetchingSessions,
    isLoading: isLoadingSessions
  } = useSessionsGetListAllSessionsQuery()

  const selectedSessionDetails =
    selectedSessionId === ''
      ? {}
      : sessions.find((session) => session.sessionId === selectedSessionId)

  const {
    data: schoolsBySessionId = [],
    isFetching: isFetchingSchools,
    isLoading: isLoadingSchools
  } = useSchoolsGetListBySessionsIdQuery({
    id: selectedSessionDetails.sessionId
  })

  if (isFetchingSessions || isLoadingSessions)
    return <Loading text='Loading Sessions' />

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageTitle>Training Session Notes</PageTitle>
        <FormControl sx={{ width: 250 }}>
          <InputLabel id='choose-session-label'>Choose Session</InputLabel>
          <Select
            label='Choose Session'
            labelId='choose-session-label'
            onChange={(e) => setSelectedSessionId(e.target.value)}
            value={selectedSessionId}>
            {sessions.map((session) => (
              <MenuItem
                key={session.sessionId}
                value={session.sessionId}>{`Training Session - ${moment
                .utc(session.sessionDateTime)
                .local()
                .format('MMMM DD, h:mm A')}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider />
      {selectedSessionDetails !== null && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: 1,
            paddingBottom: 1
          }}>
          <Typography sx={{ paddingRight: 2 }}>
            Training Session -{' '}
            {moment
              .utc(selectedSessionDetails.sessionDateTime)
              .local()
              .format('MMMM DD, h:mm A')}
          </Typography>
          <AddSessionNotes schools={schoolsBySessionId} />
        </Box>
      )}
    </Box>
  )
}

export default SessionNotes
