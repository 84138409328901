import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import {
  useLoginRedirectToStaffMutation,
  useSchoolsGetListAllQuery,
  useSessionsGetSessionsListAfterDateQuery,
  useTrainersGetListAllQuery
} from '../../hooks'
import { List, Loading, PageTitle } from '../shared'

import {
  AddSessionDrawer,
  ManageSchoolsDrawer,
  TrainersDrawer,
  TrainingSession
} from '../TrainingSessions'

const TrainingSessions = () => {
  const [openAddSessionDrawer, setOpenAddSessionDrawer] = useState(false)

  const { isLoading: isLoadingRedirect, mutate: redirectToStaff } =
    useLoginRedirectToStaffMutation()

  const { data: schools = [], isLoading: isLoadingSchools } =
    useSchoolsGetListAllQuery()

  const {
    data: sessions = [],
    isFetching: isFetchingSessions,
    isLoading: isLoadingSessions
  } = useSessionsGetSessionsListAfterDateQuery()

  const {
    data: trainers = [],
    isFetching: isFetchingTrainers,
    isLoading: isLoadingTrainers = []
  } = useTrainersGetListAllQuery()

  if (
    isFetchingSessions ||
    isFetchingTrainers ||
    isLoadingRedirect ||
    isLoadingSchools ||
    isLoadingSessions ||
    isLoadingTrainers
  )
    return <Loading text='Loading Sessions Data' />

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { sm: 'space-between' },
          alignItems: 'center',
          marginBottom: 2
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <PageTitle>Training Sessions</PageTitle>
          <Typography
            onClick={() => redirectToStaff()}
            sx={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
            Go to My Session
          </Typography>
        </Box>

        <Box
          sx={{
            paddingTop: { xs: 1, sm: 0 },
            display: 'flex'
          }}>
          <Button
            color='success'
            onClick={() => setOpenAddSessionDrawer(true)}
            sx={{ marginRight: 1 }}>
            Add Session
          </Button>
          <TrainersDrawer trainers={trainers} />
          <ManageSchoolsDrawer schools={schools} />
        </Box>
      </Box>
      <List
        Component={TrainingSession}
        data={sessions}
        emptyArrayMessage='There are no sessions'
        trainers={trainers}
        sessions={sessions}
      />
      {openAddSessionDrawer && (
        <AddSessionDrawer
          open={openAddSessionDrawer}
          schools={schools}
          setOpen={setOpenAddSessionDrawer}
          trainers={trainers}
        />
      )}
    </div>
  )
}

export default TrainingSessions
