import React from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'

import { sortArrayByFirstNameLastName } from '../../helpers'
import { List } from '../shared'

import SessionActionsMenu from './SessionActionsMenu'
import SessionItem from './SessionItem'

const TrainingSession = ({
  // DTO
  listItems,
  session,
  trainerIds,
  // Other
  isLastItem,
  sessions,
  trainers
}) => {
  const { sessionDateTime, sessionId } = session

  const formattedDate = moment
    .utc(sessionDateTime)
    .local()
    .format('MMMM DD, h:mm A')

  const getSessionTrainerNames = () => {
    let selectedTrainersInfo = []

    trainerIds.forEach((id) => {
      selectedTrainersInfo.push(
        trainers.find((trainer) => trainer.trainerId === id)
      )
    })

    selectedTrainersInfo = sortArrayByFirstNameLastName(selectedTrainersInfo)

    const names = []

    selectedTrainersInfo.forEach((trainer) => {
      if (trainer !== undefined) {
        names.push(`${trainer.firstName} ${trainer.lastName}`)
      }
    })

    const final = []

    names.forEach((name, i) => {
      final.push(i === names.length - 1 ? name : name + ', ')
    })

    return final
  }

  return (
    <Box
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
        borderBottom: !isLastItem && '2px solid black'
      }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Typography
          variant='h6'
          sx={{ marginRight: { sm: 3 }, marginBottom: { xs: 1, sm: 0 } }}>
          Training Session - {formattedDate}
        </Typography>
        <Box>
          <SessionActionsMenu
            sessionDate={formattedDate}
            sessionId={sessionId}
            sessions={sessions}
            trainers={trainers}
          />
        </Box>
      </Box>
      <Typography variant='caption'>
        Trainers: {getSessionTrainerNames()}
      </Typography>
      <List
        Component={SessionItem}
        data={listItems}
        emptyArrayMessage='Please add a school for this session'
        sessionId={sessionId}
      />
    </Box>
  )
}

export default TrainingSession
