import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        color: 'success',
        disableElevation: true,
        variant: 'contained'
      }
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: 500
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: '100%',

          [theme.breakpoints.up('sm')]: {
            width: 250
          }
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
          marginRight: theme.spacing(),

          width: '100%',

          [theme.breakpoints.up('sm')]: {
            width: 250
          }
        })
      }
    }
  },
  palette: {
    primary: {
      main: '#253686'
    },
    secondary: {
      main: '#EB8003'
    },
    error: { main: '#C90008' },
    neutral: { main: '#696969', contrastText: '#fff' },
    neutralLight: { main: '#ddd' },
    success: { main: '#039003', dark: '#036903' }
  }
})
