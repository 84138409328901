export const attendeesGetByIdQueryKey = (id) => [
  'attendees',
  'get-by-id',
  { id: parseInt(id) }
]

export const attendeesGetListBySchoolIdQueryKey = (schoolId) => [
  'attendees',
  'get-list-by-school-id',
  { schoolId: parseInt(schoolId) }
]

export const attendeesGetListBySessionIdSchoolIdQueryKey = ({
  schoolId,
  sessionId
}) => [
  'attendees',
  'get-list-by-session-id-school-id',
  { schoolId: parseInt(schoolId), sessionId: parseInt(sessionId) }
]

export const notesGetByReferenceIdAndNoteTypeQueryKey = ({
  noteType,
  referenceId
}) => [
  'notes',
  'get-by-reference-id-and-note-type',
  { noteType: parseInt(noteType), referenceId: parseInt(referenceId) }
]

export const schoolsGetListBySessionIdQueryKey = (id) => [
  'schools',
  'get-list-by-session-id',
  { id: parseInt(id) }
]

export const sessionsGetSessionsListAfterDateQueryKey = () => [
  'sessions',
  'get-sessions-list-after-date'
]

export const sessionsGetSessionsListItemsQueryKey = () => [
  'sessions',
  'get-sessions-list-items'
]

export const sessionsGetListAllSessionsQueryKey = () => [
  'sessions',
  'get-list-all-sessions'
]

export const trainersGetListAllQueryKey = () => ['trainers', 'get-list-all']
