import { styled } from '@mui/material/styles'

import { NAV_WIDTH } from '../constants'

export const StyledMain = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),

  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(2),

  maxWidth: '100vw',

  [theme.breakpoints.up('md')]: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    left: NAV_WIDTH,
    maxWidth: `calc(100% - ${NAV_WIDTH})`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))
