import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetSessionsListAfterDateQueryKey } from '../../../helpers'

const useSessionsDeleteByIdMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (sessionId) => {
      await axiosInstance.delete(`Sessions/DeleteById/${sessionId}`)

      return sessionId
    },
    {
      onSuccess: (sessionId) => {
        enqueueSnackbar('Session has been deleted', {
          variant: 'success'
        })

        queryClient.setQueryData(
          sessionsGetSessionsListAfterDateQueryKey(),
          (prevSessions) => {
            return [
              ...prevSessions.filter(
                (session) => session.session.sessionId !== sessionId
              )
            ]
          }
        )
      }
    }
  )
}

export default useSessionsDeleteByIdMutation
