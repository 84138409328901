import React, { createContext, useContext, useState } from 'react'

import {
  localStorageAuthToken,
  localStorageHasBeenAuthenticated,
  localStorageLoggedInUserDetails
} from '../constants'

const LoggedInUserContext = createContext()

function isLoggedInUserAuthenticated() {
  const hasBeenAuthenticated = localStorage.getItem(
    localStorageHasBeenAuthenticated
  )

  if (hasBeenAuthenticated !== 'true') {
    return false
  } else {
    return true
  }
}

const LoggedInUserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    isLoggedInUserAuthenticated()
  )

  const logout = () => {
    localStorage.removeItem(localStorageAuthToken)
    localStorage.removeItem(localStorageHasBeenAuthenticated)
    localStorage.removeItem(localStorageLoggedInUserDetails)
    updateIsAuthenticated()

    if (process.env.NODE_ENV !== 'development') {
      window.location.replace('https://login.enrichingstudents.com/')

      return null
    }
  }

  const updateIsAuthenticated = () => {
    const hasBeenAuthenticated = localStorage.getItem(
      localStorageHasBeenAuthenticated
    )

    if (
      hasBeenAuthenticated === undefined ||
      hasBeenAuthenticated === null ||
      hasBeenAuthenticated === false ||
      hasBeenAuthenticated === 'false'
    ) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }

  return (
    <LoggedInUserContext.Provider
      value={{
        isAuthenticated,
        logout,
        updateIsAuthenticated
      }}>
      {children}
    </LoggedInUserContext.Provider>
  )
}

const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext)
  if (!context) {
    throw new Error('useLoggedInUser must be used with a LoggedInUserProvider')
  }

  return context
}

export { LoggedInUserContext, LoggedInUserProvider, useLoggedInUser }
