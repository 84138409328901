import React from 'react'
import { Box, Typography } from '@mui/material'

import { sortByCollatingField } from '../../helpers'
import { List } from '../shared'

import SchoolItem from './SchoolItem'

const CurrentSchools = ({ schools, setupSchoolToEdit }) => {
  const sortedSchool = schools.sort(sortByCollatingField(true, 'schoolName'))

  return (
    <Box>
      <Typography sx={{ fontWeight: 'bold' }}>Current Schools</Typography>
      <List
        Component={SchoolItem}
        data={sortedSchool}
        emptyArrayMessage='There are no schools'
        keyValue='schoolId'
        setupSchoolToEdit={setupSchoolToEdit}
      />
    </Box>
  )
}

export default CurrentSchools
