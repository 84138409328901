import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  SessionNotesScreen,
  TrainingSessionsScreen,
  ValidateTokenScreen
} from '../screens'

import { StyledMain } from './styledComponents'

const Main = () => {
  return (
    <StyledMain>
      <Routes>
        <Route exact path='/sessionNotes' element={<SessionNotesScreen />} />
        <Route
          exact
          path='/trainingSessions'
          element={<TrainingSessionsScreen />}
        />
        <Route exact path='/' element={<TrainingSessionsScreen />} />
        <Route element={<ValidateTokenScreen />} exact path='/validateToken' />
      </Routes>
    </StyledMain>
  )
}

export default Main
