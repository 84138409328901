import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { schoolsGetListBySessionIdQueryKey } from '../../../helpers/queryKeys'

const useSchoolsGetListBySessionsIdQuery = ({ id }) => {
  return useQuery(
    schoolsGetListBySessionIdQueryKey(id),
    async () => {
      const response = await axiosInstance.get(
        `Schools/GetListBySessionId/${id}`
      )

      return response.data
    },
    { enabled: !!id }
  )
}

export default useSchoolsGetListBySessionsIdQuery
