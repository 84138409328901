import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'

const useSchoolsDeleteByIdMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (id) => {
      await axiosInstance.delete(`Schools/DeleteById/${id}`)

      return id
    },
    {
      onSuccess: (id) => {
        queryClient.setQueryData(['schools', 'get-list-all'], (prevSchools) => {
          return [...prevSchools.filter((school) => school.schoolId !== id)]
        })
      }
    }
  )
}

export default useSchoolsDeleteByIdMutation
