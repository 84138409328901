import React from 'react'
import { Box, Typography } from '@mui/material'

import { List } from '../shared'

import CurrentAttendeeItem from './CurrentAttendeeItem'

const CurrentAttendees = ({ currentAttendees, setupAttendeeToEdit }) => {
  return (
    <Box>
      <Typography sx={{ fontWeight: 'bold' }}>
        Currently Assigned - {currentAttendees.length} of 10
      </Typography>
      <List
        Component={CurrentAttendeeItem}
        data={currentAttendees}
        emptyArrayMessage='There are no attendees'
        keyValue='attendeeId'
        setupAttendeeToEdit={setupAttendeeToEdit}
      />
    </Box>
  )
}

export default CurrentAttendees
