import React from 'react'

import { LabeledCheckbox } from '../shared'

const SessionTrainerCheckbox = ({
  firstName,
  lastName,
  onChange,
  sessionTrainers,
  trainerId
}) => {
  const isChecked = () => {
    const result = sessionTrainers.find((trainer) => trainer === trainerId)

    return result === undefined ? false : true
  }
  return (
    <LabeledCheckbox
      checked={isChecked()}
      id={trainerId.toString()}
      onChange={onChange}
      label={firstName + ' ' + lastName}
    />
  )
}

export default SessionTrainerCheckbox
