import React from "react";
import { ListItem, ListItemText } from "@mui/material";

import { StyledLink, StyledListItemIcon } from "./styledComponents";

const NavLink = ({ label, onClick, Icon, pathName, to }) => {
  return (
    <StyledLink
      onClick={onClick}
      to={{ pathname: to, state: { from: pathName } }}
    >
      <ListItem button key={label}>
        <StyledListItemIcon>{Icon}</StyledListItemIcon>
        <ListItemText
          sx={{ color: "#959292", fontSize: 17 }}
          primary={label}
          style={{ paddingLeft: 0 }}
        />
      </ListItem>
    </StyledLink>
  );
};

export default NavLink;
