import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetSessionsListAfterDateQueryKey } from '../../../helpers'

const useAttendeesRunImportsMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (sessionId) => {
      await axiosInstance.put(`Attendees/RunImports/${sessionId}`)

      return { sessionId }
    },
    {
      onError: () => {
        enqueueSnackbar('Data was not imported for this session', {
          variant: 'error'
        })
      },
      onSuccess: ({ sessionId }) => {
        enqueueSnackbar('Data has been imported for this session', {
          variant: 'success'
        })

        queryClient.setQueryData(
          sessionsGetSessionsListAfterDateQueryKey(),
          (prevSessions) => {
            return prevSessions.map((prevSession) => {
              if (prevSession.session.sessionId !== sessionId)
                return prevSession

              const newListItems = prevSession.listItems
              newListItems.forEach((listItem) => {
                listItem.requiresImport = false
              })
              return { ...prevSession, listItems: newListItems }
            })
          }
        )
      }
    }
  )
}

export default useAttendeesRunImportsMutation
