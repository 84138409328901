import React from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import Main from './Main'
import Nav from './Nav'
import { useLoggedInUser } from '../../context'
import MainUnauthenticated from './MainUnauthenticated'

const Layout = ({ isAuthenticated }) => {
  return (
    <div style={{ display: 'flex' }}>
      {isAuthenticated ? (
        <>
          <Nav />
          <Main />
        </>
      ) : (
        <MainUnauthenticated />
      )}
    </div>
  )
}

const QueryClientAndNotificationsProviders = () => {
  const { isAuthenticated, logout } = useLoggedInUser()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error) => {
        //from here we can handle logouts
        if (error.response.status === 401) {
          logout()
        }
      }
    })
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Layout isAuthenticated={isAuthenticated} /> <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default QueryClientAndNotificationsProviders
