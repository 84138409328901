import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import {
  attendeesGetListBySessionIdSchoolIdQueryKey,
  sessionsGetSessionsListAfterDateQueryKey
} from '../../../helpers/queryKeys'

const useAttendeesDeleteByIdMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ attendeeId, schoolId, sessionId }) => {
      await axiosInstance.delete(`Attendees/DeleteById/${attendeeId}`)

      return { attendeeId, schoolId, sessionId }
    },
    {
      onSuccess: ({ attendeeId, schoolId, sessionId }) => {
        enqueueSnackbar('Attendee has been removed', {
          variant: 'success'
        })

        // Update list of cached Attendees
        queryClient.setQueryData(
          attendeesGetListBySessionIdSchoolIdQueryKey({ schoolId, sessionId }),
          (prevAttendees) => {
            return [
              ...prevAttendees.filter(
                (attendee) => attendee.attendeeId !== attendeeId
              )
            ]
          }
        )

        // Update cached session data
        queryClient.setQueryData(
          sessionsGetSessionsListAfterDateQueryKey(),
          (prevSessions) => {
            return [
              ...prevSessions.map((prevSession) => {
                if (prevSession.session.sessionId !== sessionId)
                  return prevSession

                const newListItems = prevSession.listItems.map((listItem) => {
                  if (listItem.schoolId !== schoolId) return listItem

                  return {
                    ...listItem,
                    isFullAttendance: false,
                    numberOfAttendees: listItem.numberOfAttendees - 1
                  }
                })

                return { ...prevSession, listItems: newListItems }
              })
            ]
          }
        )
      }
    }
  )
}

export default useAttendeesDeleteByIdMutation
