import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { attendeesGetListBySessionIdSchoolIdQueryKey } from '../../../helpers/queryKeys'

const useAttendeesGetListBySessionIdSchoolIdQuery = ({
  enabled = true,
  schoolId,
  sessionId
}) => {
  return useQuery(
    attendeesGetListBySessionIdSchoolIdQueryKey({ schoolId, sessionId }),
    async () => {
      const response = await axiosInstance.get(
        `Attendees/GetListBySessionIdSchoolId/${sessionId}/${schoolId}`
      )

      return response.data
    },
    {
      enabled
    }
  )
}

export default useAttendeesGetListBySessionIdSchoolIdQuery
