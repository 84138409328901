import moment from 'moment'
import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetSessionsListAfterDateQueryKey } from '../../../helpers'

const useSessionsGetSessionsListAfterDateQuery = ({ date = moment() } = {}) => {
  const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')

  return useQuery(
    sessionsGetSessionsListAfterDateQueryKey(),
    async () => {
      const response = await axiosInstance.get(
        `Sessions/GetSessionsListAfterDate/${formattedDate}`
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 5
    }
  )
}

export default useSessionsGetSessionsListAfterDateQuery
