import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(),
  fontSize: 21,
  fontWeight: 300
}))

const SectionTitle = ({ text }) => {
  return <StyledSectionTitle>{text}</StyledSectionTitle>
}

export default SectionTitle
