import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'

import { LoggedInUserProvider } from './context'
import { Layout } from './components/layout'
import { theme } from './theme'

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{ maxWidth: '800px' }}
            maxSnack={5}>
            <CssBaseline />
            <LoggedInUserProvider>
              <Layout />
            </LoggedInUserProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
