import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledEmptyArrayMessage = styled('div', {
  shouldForwardProp: (prop) => prop !== 'textAlign'
})(({ textAlign, theme }) => ({
  textAlign: textAlign,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2)
}))

const EmptyArrayMessage = ({ text, textAlign = 'center' }) => {
  return (
    <StyledEmptyArrayMessage
      data-testid='empty-array-message'
      textAlign={textAlign}>
      <Typography>{text}</Typography>
    </StyledEmptyArrayMessage>
  )
}

export default EmptyArrayMessage
