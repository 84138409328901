import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'

const useTrainersDeleteByIdMutation = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (trainerId) => {
      await axiosInstance.put(`Trainers/RunImports/${trainerId}`)

      return { trainerId }
    },
    {
      onError: () => {
        enqueueSnackbar('Data was not imported for this trainer', {
          variant: 'error'
        })
      },
      onSuccess: () => {
        enqueueSnackbar('Data has been imported for this trainer', {
          variant: 'success'
        })
      }
    }
  )
}

export default useTrainersDeleteByIdMutation
