import React, { useEffect, useState } from 'react'
import { Box, Button, Drawer, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { useSchoolsUpdateSchoolMutation } from '../../hooks'
import { FlexColumn, Loading } from '../shared'

import CurrentSchools from './CurrentSchools'

const INITIAL_STATE = {
  schoolId: 0,
  schoolName: '',
  specificSchoolQuestion: ''
}

const AddSchoolDrawerMain = ({ schools }) => {
  const { errors, handleSubmit, register } = useForm()

  const [open, setOpen] = useState(false)
  const [schoolInfo, setSchoolInfo] = useState(INITIAL_STATE)
  const [settingUpRecordToEdit, setSettingUpRecordToEdit] = useState(false)

  const closeDrawer = (reason) => {
    if (reason === 'backdropClick') {
      return
    }

    setSchoolInfo(INITIAL_STATE)

    setOpen(false)
  }

  const {
    isLoading: isSavingSchool,
    mutate: saveSchool,
    status: saveSchoolStatus
  } = useSchoolsUpdateSchoolMutation()

  useEffect(() => {
    if (saveSchoolStatus === 'success') {
      setSchoolInfo(INITIAL_STATE)
    }
  }, [saveSchoolStatus])

  const onSubmit = (formData) => {
    saveSchool({
      ...schoolInfo,
      ...formData
    })
  }

  const setupSchoolToEdit = (schoolId) => {
    setSettingUpRecordToEdit(true)

    const school = schools.find((school) => school.schoolId === schoolId)

    setSchoolInfo(school)

    setTimeout(() => {
      setSettingUpRecordToEdit(false)
    }, '500')
  }

  if (isSavingSchool) return <Loading text='Saving School' />

  return (
    <>
      <Button
        color='neutral'
        onClick={() => setOpen(true)}
        sx={{ marginRight: 1 }}>
        Manage Schools
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={(e, reason) => closeDrawer(reason)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn sx={{ padding: 2, height: '100%' }}>
            {!settingUpRecordToEdit ? (
              <>
                <Typography
                  sx={{
                    fontSize: 21,
                    fontWeight: 300,
                    paddingBottom: 2
                  }}>
                  Manage Schools
                </Typography>
                <TextField
                  data-testid='school-name'
                  defaultValue={schoolInfo.schoolName}
                  error={Boolean(errors.schoolName)}
                  helperText={
                    errors.schoolName ? errors.schoolName.message : null
                  }
                  inputRef={register({
                    required: 'Please enter a School Name'
                  })}
                  label='School Name'
                  name='schoolName'
                />
              </>
            ) : (
              <Typography>Setting Up Schools</Typography>
            )}
            <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 4 }}>
              <Button size='small' sx={{ marginRight: 1 }} type='submit'>
                Save
              </Button>
              <Button color='neutralLight' onClick={closeDrawer} size='small'>
                Close
              </Button>
            </Box>
            <CurrentSchools
              schools={schools}
              setupSchoolToEdit={setupSchoolToEdit}
            />
          </FlexColumn>
        </form>
      </Drawer>
    </>
  )
}

export default AddSchoolDrawerMain
