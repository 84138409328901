import React, { forwardRef, useState } from 'react'
import { Button, MenuItem, Modal } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import SectionTitle from './SectionTitle'

const ConfirmDeleteMessage = styled('p')(() => ({
  textAlign: 'center'
}))

const ModalContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ModalInterior = styled('div')(() => ({
  position: 'absolute',
  width: '100vw',
  height: '100vh',

  backdropFilter: `blur(30px) brightness(185%) saturate(100%)`,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledCloseIcon = styled(Close)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  marginRight: 16,
  marginTop: 16,
  fontSize: 32,
  cursor: 'pointer'
}))

export const StyledSpan = styled('span')(() => ({
  textDecoration: 'underline',
  color: 'blue',

  '&:hover': {
    cursor: 'pointer'
  }
}))

const DeleteModal = forwardRef((props, ref) => {
  const {
    confirmDeleteCallback,
    confirmDeleteLabel,
    confirmDeleteMessage,
    itemName,
    text,
    useMenuItem = false
  } = props

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmDelete = () => {
    setOpen(false)

    confirmDeleteCallback()
  }

  return (
    <>
      {useMenuItem ? (
        <MenuItem ref={ref} onClick={handleOpen}>
          {text}
        </MenuItem>
      ) : (
        <StyledSpan onClick={handleOpen}>Remove</StyledSpan>
      )}
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}>
        <ModalInterior>
          <StyledCloseIcon onClick={handleClose} />
          <ModalContent>
            <SectionTitle text={`You are about to delete:`} />
            <h3>{itemName}</h3>
            <ConfirmDeleteMessage>{confirmDeleteMessage}</ConfirmDeleteMessage>
          </ModalContent>
          <Button
            color='error'
            data-testid='confirm-delete-button'
            onClick={handleConfirmDelete}>
            {confirmDeleteLabel}
          </Button>
        </ModalInterior>
      </Modal>
    </>
  )
})

export default DeleteModal
