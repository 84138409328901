import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ValidateTokenScreen } from '../screens'

import { StyledMain } from './styledComponents'

const MainUnauthenticated = () => {
  return (
    <StyledMain>
      <Routes>
        <Route element={<ValidateTokenScreen />} exact path='/validateToken' />
      </Routes>
    </StyledMain>
  )
}

export default MainUnauthenticated
