import React from 'react'
import { Box, Chip } from '@mui/material'

import { ListItemContainer, ListItemText } from '../shared'

import AddAttendeesDrawer from './AddAttendeesDrawer'

const SessionItem = ({
  // DTO
  //isFullAttendance,
  numberOfAttendees,
  requiresImport,
  requiresNotification,
  schoolId,
  schoolName,
  // Other
  isLastItem,
  sessionId
}) => {
  const isFullAttendance = numberOfAttendees > 9

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', sm: '1/4' }
        }}>
        {schoolName}
      </ListItemText>
      <ListItemText sx={{ gridColumn: { xs: '1/13', sm: '4/6' } }}>
        Attendees {numberOfAttendees} of 10
      </ListItemText>
      <ListItemText sx={{ gridColumn: { xs: '1/13', sm: '6/8' } }}>
        School ID {schoolId}
      </ListItemText>
      <Box
        sx={{
          gridColumn: { xs: '1/13', sm: '8/13' },
          display: 'flex'
        }}>
        <AddAttendeesDrawer
          schoolId={schoolId}
          sessionId={sessionId}
          schoolName={schoolName}
        />
        {isFullAttendance && (
          <Chip
            color='secondary'
            label='Full Attendance'
            sx={{ marginRight: 1 }}
          />
        )}
        {!requiresImport && numberOfAttendees > 0 && (
          <Chip color='secondary' label='Imported' />
        )}
        {!requiresNotification && numberOfAttendees > 0 && (
          <Chip color='secondary' label='Notified' />
        )}
      </Box>
    </ListItemContainer>
  )
}

export default SessionItem
