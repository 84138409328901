import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { axiosInstance } from '../../axiosInstance'
import {
  localStorageAuthToken,
  localStorageHasBeenAuthenticated
} from '../../constants'
import { useLoggedInUser } from '../../context'
import { Loading } from '../shared'

const useLoginValidateTokenQuery = ({ token, updateIsAuthenticated }) => {
  const navigate = useNavigate()

  return useQuery(
    ['login', 'validate-token'],
    async () => {
      const response = await axiosInstance.post(`Login/validatetoken`, {
        stringValue: token
      })

      return response.data
    },
    {
      onSuccess: (data) => {
        if (data.authToken) {
          localStorage.setItem(localStorageAuthToken, data.authToken)
          localStorage.setItem(localStorageHasBeenAuthenticated, 'true')

          axiosInstance.defaults.headers.esauthtoken = data.authToken

          updateIsAuthenticated()

          navigate('/', { replace: true })
        } else {
          localStorage.setItem(localStorageHasBeenAuthenticated, 'false')
        }
      }
    }
  )
}

const ValidateToken = () => {
  const { updateIsAuthenticated } = useLoggedInUser()

  let [searchParams] = useSearchParams()

  const tokenSearchParam = searchParams.get('token')

  const { data, isFetching, isLoading } = useLoginValidateTokenQuery({
    token: tokenSearchParam,
    updateIsAuthenticated
  })

  if (isFetching || isLoading)
    return (
      <Loading
        sx={{
          width: '100vw !important',
          height: '100vh !important',
          position: 'fixed !important',
          top: 0,
          left: 0,
          zIndex: 1000
        }}
        text='Logging Into Enriching Students Training Admin'
      />
    )

  return (
    <div>
      {data.errorMessage !== '' && <Typography>{data.errorMessage}</Typography>}
    </div>
  )
}

export default ValidateToken
