import axios from 'axios'
import { localStorageAuthToken } from '../constants/localStorage'

export const getToken = () => localStorage.getItem(localStorageAuthToken)

export const getAuthorizationHeader = () => getToken()

export const axiosInstance = axios.create({
  baseURL: 'https://trainingadminapi.enrichingstudents.com/api/',
  headers: {
    esauthtoken: getAuthorizationHeader()
  }
})
