import { styled } from "@mui/material/styles";
import { ListItemIcon } from "@mui/material";

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: "#959292",

  [theme.breakpoints.up("md")]: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),

    minWidth: 0,
  },
}));
