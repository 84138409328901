import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLoading = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#eee',
  zIndex: 1000,

  [theme.breakpoints.up('md')]: {
    height: '100vh'
  }
}))

const Loading = ({ dataTestId = 'loading-component', sx, text }) => {
  return (
    <StyledLoading data-testid={dataTestId} sx={sx}>
      <CircularProgress size={80} sx={{ color: 'primary.main' }} />
      <Typography sx={{ marginTop: 2 }} variant='h4' align='center'>
        {text}
      </Typography>
    </StyledLoading>
  )
}

export default Loading
