import { styled } from "@mui/material/styles";

export const StyledLogo = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),

  img: {
    height: 100,
    width: 100,
  },
}));
