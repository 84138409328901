import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'

const useSchoolsGetListAllQuery = () => {
  return useQuery(['schools', 'get-list-all'], async () => {
    const response = await axiosInstance.get(`Schools/GetListAll`)

    return response.data
  })
}

export default useSchoolsGetListAllQuery
