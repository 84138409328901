import React, { forwardRef } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CalendarMonth } from '@mui/icons-material'
import moment from 'moment'

export const StyledDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  width: '100%',

  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: 250,

    marginRight: theme.spacing()
  }
}))

const EsDatePicker = forwardRef(
  (
    {
      className,
      dataTestId,
      disableWeekends = true,
      label,
      onChange,
      value,
      sx,
      ...otherProps
    },
    ref
  ) => {
    function shouldDisableWeekends(date) {
      return date._d.getDay() === 0 || date._d.getDay() === 6
    }

    return (
      <StyledDatePicker
        inputFormat='dddd Do'
        disableMaskedInput={true}
        onChange={onChange}
        renderInput={(params) => {
          // Params includes an InputProps property
          // that throws an error when spread to the Input component
          delete params.InputProps
          return (
            <FormControl variant='standard' sx={sx}>
              <InputLabel>{label}</InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position='end'>
                    <CalendarMonth sx={{ '&:hover': { cursor: 'pointer' } }} />
                  </InputAdornment>
                }
                variant='standard'
                {...params}
              />
            </FormControl>
          )
        }}
        shouldDisableDate={disableWeekends && shouldDisableWeekends}
        value={moment(value, ['MM/DD/YYYY', 'YYYY-MM-DD']).format()}
        {...otherProps}
      />
    )
  }
)

export default EsDatePicker
