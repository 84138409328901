import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { sortArrayByLastNameFirstName } from '../../../helpers'
import { trainersGetListAllQueryKey } from '../../../helpers/queryKeys'

const useTrainersGetListAllQuery = () => {
  return useQuery(
    trainersGetListAllQueryKey(),
    async () => {
      const response = await axiosInstance.get('Trainers/GetListAll')

      return sortArrayByLastNameFirstName(response.data)
    },
    {
      staleTime: 1000 * 60 * 5
    }
  )
}

export default useTrainersGetListAllQuery
