import React, { useState } from 'react'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import {
  useTrainersDeleteByIdMutation,
  useTrainersRunImportsMutation
} from '../../hooks'
import { DeleteModal } from '../shared'

const CurrentTrainerItem = ({
  // DTO
  emailAddress,
  firstName,
  lastName,
  trainerId,
  // Other
  isLastItem,
  setupTrainerToEdit
}) => {
  const { isLoading: isDeletingTrainer, mutate: deleteTrainer } =
    useTrainersDeleteByIdMutation()

  const { isLoading: isImportingTrainerData, mutate: importTrainer } =
    useTrainersRunImportsMutation()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    handleClose()
    setupTrainerToEdit(trainerId)
  }

  const handleRefreshClick = () => {
    handleClose()
    importTrainer(trainerId)
  }

  if (isDeletingTrainer) return 'Deleting...'

  if (isImportingTrainerData) return 'Importing ...'

  return (
    <Box
      data-testid='current-trainer-item'
      sx={{
        borderBottom: !isLastItem && '1px solid #d9d9d9',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <Typography>
        {firstName} {lastName} - {emailAddress}
      </Typography>
      <Button
        data-testid={`trainer-action-button-for-${trainerId}`}
        id='trainer-action-button'
        aria-controls={open ? 'trainer-action-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='small'
        sx={{ marginLeft: 1 }}
        color='neutralLight'>
        Actions
      </Button>
      <Menu
        id='trainer-action-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'trainer-action-button'
        }}>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleRefreshClick}>Refresh School Data</MenuItem>
        <DeleteModal
          confirmDeleteCallback={() => deleteTrainer(trainerId)}
          confirmDeleteLabel='Delete Trainer'
          confirmDeleteMessage='Are you sure you want to delete this trainer?'
          itemName={firstName + ' ' + lastName}
          text='Remove'
          useMenuItem
        />
      </Menu>
    </Box>
  )
}

export default CurrentTrainerItem
