import React from 'react'
import { Box, Typography } from '@mui/material'

import { useSchoolsDeleteByIdMutation } from '../../hooks'
import { DeleteModal } from '../shared'

import { StyledSpan } from './styledComponents'

const SchoolItem = ({
  // DTO
  schoolId,
  schoolName,
  // Other
  isLastItem,
  setupSchoolToEdit
}) => {
  const { isLoading: isDeletingSchool, mutate: deleteSchool } =
    useSchoolsDeleteByIdMutation()

  if (isDeletingSchool) return 'Deleting...'

  return (
    <Box
      data-testid='current-trainer-item'
      sx={{
        borderBottom: !isLastItem && '1px solid #d9d9d9',
        paddingTop: 0.5,
        paddingBottom: 0.5
      }}>
      <Typography>
        {schoolName} -{' '}
        <StyledSpan onClick={() => setupSchoolToEdit(schoolId)}>
          Edit |&nbsp;
        </StyledSpan>
        <DeleteModal
          confirmDeleteCallback={() => deleteSchool(schoolId)}
          confirmDeleteLabel='Delete School'
          confirmDeleteMessage='Are you sure you want to delete this school?'
          itemName={schoolName}
        />
      </Typography>
    </Box>
  )
}

export default SchoolItem
