import { useMutation } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'

const useLoginRedirectToStaffMutation = () => {
  return useMutation(
    async (id) => {
      const response = await axiosInstance.get('Login/redirectToStaff')

      return response
    },
    {
      onSuccess: (response) => {
        window.open(response.data, '_blank', 'noreferrer')
      }
    }
  )
}

export default useLoginRedirectToStaffMutation
