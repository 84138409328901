import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { DeleteModal } from '../shared'

const AttendeeActionsMenu = ({
  attendeeId,
  deleteAttendee,
  firstName,
  isDataImported,
  lastName,
  notifyAttendee,
  schoolId,
  sessionId,
  setupAttendeeToEdit
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    handleClose()
    setupAttendeeToEdit(attendeeId)
  }

  const handleResendNotificationClick = () => {
    handleClose()
    notifyAttendee(attendeeId)
  }

  return (
    <>
      <Button
        data-testid={`attendee-action-button-for-${attendeeId}`}
        id='attendee-action-button'
        aria-controls={open ? 'attendee-action-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='small'
        color='neutralLight'>
        Actions
      </Button>
      <Menu
        id='attendee-action-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'attendee-action-button'
        }}>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <DeleteModal
          confirmDeleteCallback={() =>
            deleteAttendee({ attendeeId, schoolId, sessionId })
          }
          confirmDeleteLabel='Delete Attendee'
          confirmDeleteMessage='Are you sure you want to delete this attendee?'
          itemName={firstName + ' ' + lastName}
          text='Remove'
          useMenuItem
        />
        {isDataImported && (
          <MenuItem onClick={handleResendNotificationClick}>
            Resend Notification
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AttendeeActionsMenu
