export const sortArrayByLastNameFirstName = (array) => {
  return array.sort(function (a, b) {
    if (a.lastName < b.lastName) {
      return -1
    }
    if (a.lastName > b.lastName) {
      return 1
    }
    if (a.firstName < b.firstName) {
      return -1
    }
    return 1
  })
}

export const sortArrayByFirstNameLastName = (array) => {
  return array.sort((a, b) => {
    const result = a.firstName.localeCompare(b.firstName)

    return result !== 0 ? result : a.lastName.localeCompare(b.lastName)
  })
}

export const sortByCollatingField = (ascValue, field) => {
  //constructor for objects that enable
  //language sensitive string comparison
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  if (ascValue) {
    return (a, b) => collator.compare(a[field], b[field])
  } else {
    return (a, b) => collator.compare(b[field], a[field])
  }
}
