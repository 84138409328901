import React, { useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { FlexColumn } from '../shared'

const AddSessionNotes = ({ schools }) => {
  const { control, errors, handleSubmit } = useForm()

  const [open, setOpen] = useState(false)

  const closeDrawer = (reason) => {
    if (reason === 'backdropClick') {
      return
    }

    setOpen(false)
  }

  const onSubmit = (formData) => {
    console.log(formData, 'formData')
  }

  return (
    <>
      <Button
        color='neutralLight'
        onClick={() => setOpen(true)}
        size='small'
        sx={{ marginRight: 1 }}>
        Add Session Notes
      </Button>
      <Drawer
        anchor='right'
        data-testid='add-attendee-drawer'
        open={open}
        onClose={(e, reason) => closeDrawer(reason)}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: 500 }}>
          {console.log(errors, 'errors')}
          <FlexColumn sx={{ padding: 2, height: '100%' }}>
            <Typography
              sx={{
                fontSize: 21,
                fontWeight: 300,
                paddingBottom: 2
              }}>
              Add Session Note
            </Typography>
            <FormControl error={Boolean(errors.selectedSchool)}>
              <InputLabel id={'select-school'}>Select School</InputLabel>
              <Controller
                as={
                  <Select labelId={'select-school'} label={'Select School'}>
                    {schools.map((school) => (
                      <MenuItem key={school.schoolId} value={school.schoolId}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
                defaultValue={''}
                name={'selectedSchool'}
                rules={{ required: 'Please select a school' }}
              />
              <FormHelperText>
                {errors.selectedSchool && errors.selectedSchool.message}
              </FormHelperText>
            </FormControl>
            <FormControl error={Boolean(errors.noteText)} sx={{ marginTop: 2 }}>
              <Controller
                control={control}
                defaultValue={''}
                name='noteText'
                rules={{ required: 'Please add a note' }}
                render={({ onChange, value }) => (
                  <TextareaAutosize
                    aria-label='Add a note'
                    minRows={5}
                    onChange={onChange}
                    placeholder='Add a note'
                  />
                )}
              />
              <FormHelperText>
                {errors.noteText && errors.noteText.message}
              </FormHelperText>
            </FormControl>
            <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 4 }}>
              <Button size='small' sx={{ marginRight: 1 }} type='submit'>
                Save
              </Button>
              <Button color='neutralLight' onClick={closeDrawer} size='small'>
                Close
              </Button>
            </Box>
          </FlexColumn>
        </form>
      </Drawer>
    </>
  )
}

export default AddSessionNotes
