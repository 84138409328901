import React, { useState } from 'react'
import { Box, Button, Drawer, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { useTrainersWriteTrainerMutation } from '../../hooks'
import { FlexColumn, Loading } from '../shared'

import CurrentTrainers from './CurrentTrainers'

const INITIAL_TRAINER_STATE = {
  emailAddress: '',
  firstName: '',
  lastName: '',
  positionDepartment: '',
  trainerId: 0
}

const TrainersDrawer = ({ trainers }) => {
  const { errors, handleSubmit, register } = useForm()

  const [open, setOpen] = useState(false)
  const [settingUpRecordToEdit, setSettingUpRecordToEdit] = useState(false)
  const [trainerInfo, setTrainerInfo] = useState(INITIAL_TRAINER_STATE)

  const closeDrawer = (reason) => {
    if (reason === 'backdropClick') {
      return
    }

    setTrainerInfo(INITIAL_TRAINER_STATE)

    setOpen(false)
  }

  const { isLoading: isSavingTrainer, mutate: saveTrainer } =
    useTrainersWriteTrainerMutation({
      resetLocalState: () => {
        setTrainerInfo(INITIAL_TRAINER_STATE)
      }
    })

  const onSubmit = (formData) => {
    saveTrainer({
      ...formData,
      positionDepartment: '',
      trainerId: trainerInfo.trainerId
    })
  }

  const setupTrainerToEdit = (trainerId) => {
    setSettingUpRecordToEdit(true)

    const trainer = trainers.find((trainer) => trainer.trainerId === trainerId)

    setTrainerInfo(trainer)

    setTimeout(() => {
      setSettingUpRecordToEdit(false)
    }, '500')
  }

  if (isSavingTrainer) return <Loading text='Saving Trainer' />

  return (
    <>
      <Button
        color='neutral'
        onClick={() => setOpen(true)}
        sx={{ marginRight: 1 }}>
        Manage Trainers
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={(e, reason) => closeDrawer(reason)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn sx={{ padding: 2, height: '100%' }}>
            {!settingUpRecordToEdit ? (
              <>
                <Typography
                  sx={{
                    fontSize: 21,
                    fontWeight: 300,
                    paddingBottom: 2
                  }}>
                  Manage Trainers
                </Typography>
                <TextField
                  data-testid='trainer-first-name'
                  defaultValue={trainerInfo.firstName}
                  error={Boolean(errors.firstName)}
                  helperText={
                    errors.firstName ? errors.firstName.message : null
                  }
                  inputRef={register({
                    required: 'Please enter a First Name'
                  })}
                  label='Trainer First Name'
                  name='firstName'
                />
                <TextField
                  data-testid='trainer-last-name'
                  defaultValue={trainerInfo.lastName}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName ? errors.lastName.message : null}
                  inputRef={register({
                    required: 'Please enter a Last Name'
                  })}
                  label='Trainer Last Name'
                  name='lastName'
                />
                <TextField
                  data-testid='trainer-email-address'
                  defaultValue={trainerInfo.emailAddress}
                  error={Boolean(errors.emailAddress)}
                  helperText={
                    errors.emailAddress ? errors.emailAddress.message : null
                  }
                  inputRef={register({
                    required: 'Please enter an Email Address'
                  })}
                  label='Trainer Email Address'
                  name='emailAddress'
                />
              </>
            ) : (
              <Typography>Setting Up Trainer</Typography>
            )}
            <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 4 }}>
              <Button size='small' sx={{ marginRight: 1 }} type='submit'>
                Save
              </Button>
              <Button color='neutralLight' onClick={closeDrawer} size='small'>
                Close
              </Button>
            </Box>
            <CurrentTrainers
              setupTrainerToEdit={setupTrainerToEdit}
              trainers={trainers}
            />
          </FlexColumn>
        </form>
      </Drawer>
    </>
  )
}

export default TrainersDrawer
