import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetSessionsListAfterDateQueryKey } from '../../../helpers'

const useSessionsWriteSessionWithSessionTrainersAndSchools = ({ schools }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({
      sessionDateTime,
      sessionId = 0,
      sessionSchools,
      sessionTrainers,
      zoomMeetingID,
      zoomMeetingPassCode,
      zoomMeetingURL
    }) => {
      let schools = []

      sessionSchools.forEach((school) => {
        schools.push({
          sessionSchoolId: 0,
          sessionId,
          schoolId: school
        })
      })

      let trainers = []

      sessionTrainers.forEach((trainer) => {
        trainers.push({
          sessionTrainerId: 0,
          sessionId,
          trainerId: trainer
        })
      })

      const response = await axiosInstance.put(
        'Sessions/WriteSessionWithSessionTrainersAndSchools',
        {
          sessionDateTime,
          sessionId,
          sessionSchools: schools,
          sessionTrainers: trainers,
          zoomMeetingID,
          zoomMeetingPassCode,
          zoomMeetingURL
        }
      )

      return { data: response.data, sessionId }
    },
    {
      onSuccess: (response) => {
        enqueueSnackbar(
          response.sessionId === 0
            ? 'Session has been created'
            : 'Session has been edited',
          {
            variant: 'success'
          }
        )

        queryClient.setQueryData(
          sessionsGetSessionsListAfterDateQueryKey(),
          (prevSessions) => {
            let trainerIds = []

            response.data.sessionTrainers.forEach((trainer) =>
              trainerIds.push(trainer.trainerId)
            )

            let listItems = []

            response.data.sessionSchools.forEach((school) => {
              const existingDetails = prevSessions
                .find(
                  (prevSession) =>
                    prevSession.session.sessionId === response.data.sessionId
                )
                ?.listItems.find(
                  (listItem) => listItem.schoolId === school.schoolId
                )

              const schoolInfo = schools.find(
                (s) => s.schoolId === school.schoolId
              )

              listItems.push({
                isFullAttendance: !existingDetails
                  ? false
                  : existingDetails.isFullAttendance,
                numberOfAttendees: !existingDetails
                  ? 0
                  : existingDetails.numberOfAttendees,
                requiresImport: !existingDetails
                  ? false
                  : existingDetails.requiresImport,
                requiresNotification: !existingDetails
                  ? false
                  : existingDetails.requiresNotification,
                schoolId: school.schoolId,
                schoolName: schoolInfo.schoolName
              })
            })

            // New Session
            if (response.sessionId === 0) {
              const collator = new Intl.Collator('en', {
                numeric: true,
                sensitivity: 'base'
              })

              return [
                ...prevSessions,
                {
                  listItems,
                  session: response.data,
                  trainerIds
                }
              ].sort((a, b) =>
                collator.compare(
                  a.session.sessionDateTime,
                  b.session.sessionDateTime
                )
              )
            }

            // Edit existing session
            return prevSessions.map((prevSession) => {
              if (prevSession.session.sessionId !== response.data.sessionId)
                return prevSession
              return {
                listItems,
                session: {
                  sessionDateTime: response.data.sessionDateTime,
                  sessionId: response.data.sessionId,
                  zoomMeetingID: response.data.zoomMeetingID,
                  zoomMeetingPassCode: response.data.zoomMeetingPassCode,
                  zoomMeetingURL: response.data.zoomMeetingURL
                },
                trainerIds
              }
            })
          }
        )
      }
    }
  )
}

export default useSessionsWriteSessionWithSessionTrainersAndSchools
