import React from 'react'
import { Box, Typography } from '@mui/material'

import { sortArrayByFirstNameLastName } from '../../helpers'
import { List } from '../shared'

import CurrentTrainerItem from './CurrentTrainerItem'

const CurrentTrainers = ({ setupTrainerToEdit, trainers }) => {
  const sortedTrainers = sortArrayByFirstNameLastName(trainers)

  return (
    <Box>
      <Typography sx={{ fontWeight: 'bold' }}>Current Trainers</Typography>
      <List
        Component={CurrentTrainerItem}
        data={sortedTrainers}
        emptyArrayMessage='There are no trainers'
        keyValue='trainerId'
        setupTrainerToEdit={setupTrainerToEdit}
      />
    </Box>
  )
}

export default CurrentTrainers
