import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { sessionsGetSessionsListAfterDateQueryKey } from '../../../helpers'

const useSessionsNotifySessionAttendeesMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (sessionId) => {
      await axiosInstance.put(`Sessions/NotifySessionAttendees/${sessionId}`)

      return sessionId
    },
    {
      onSuccess: (sessionId) => {
        enqueueSnackbar('Attendees have been notified', {
          variant: 'success'
        })

        queryClient.setQueryData(
          sessionsGetSessionsListAfterDateQueryKey(),
          (prevSessions) => {
            return prevSessions.map((prevSession) => {
              if (prevSession.session.sessionId !== sessionId)
                return prevSession

              return {
                ...prevSession,
                listItems: prevSession.listItems.map((listItem) => {
                  return { ...listItem, requiresNotification: false }
                })
              }
            })
          }
        )
      }
    }
  )
}

export default useSessionsNotifySessionAttendeesMutation
