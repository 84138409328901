import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'

const useSchoolsUpdateSchoolMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ schoolId, schoolName, specificSchoolQuestion }) => {
      const response = await axiosInstance.put(`Schools/UpdateSchool`, {
        schoolId,
        schoolName,
        specificSchoolQuestion
      })

      console.log(response, 'response')

      return { idFromServer: response.data, schoolId, schoolName }
    },
    {
      onSuccess: ({ idFromServer, schoolId, schoolName }) => {
        const isNewRecord = idFromServer !== schoolId

        enqueueSnackbar('School has been saved', {
          variant: 'success'
        })

        queryClient.setQueryData(['schools', 'get-list-all'], (prevSchools) => {
          console.log(prevSchools, 'prevSchools')
          console.log(isNewRecord, 'isNewRecord')
          if (isNewRecord) {
            return [...prevSchools, { schoolId: idFromServer, schoolName }]
          }

          return [
            ...prevSchools.map((school) => {
              if (school.schoolId !== schoolId) return school

              return { ...school, schoolName }
            })
          ]
        })
      }
    }
  )
}

export default useSchoolsUpdateSchoolMutation
