import { styled } from '@mui/material/styles'

export const StyledSpan = styled('span')(() => ({
  textDecoration: 'underline',
  color: 'blue',

  '&:hover': {
    cursor: 'pointer'
  }
}))
