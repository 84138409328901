import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledListItem = styled("div", {
  shouldForwardProp: (prop) => prop !== "displayColumn",
})(({ displayColumn, theme }) => ({
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

    display: "flex",
    flexDirection: displayColumn && "column",
    alignItems: displayColumn ? "baseline" : "center",
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: 14,
}));

const ListItem = ({
  children,
  dangerouslySetInnerHtml = false,
  dataTestId,
  displayColumn = false,
  labelText,
  sx,
}) => {
  return (
    <StyledListItem displayColumn={displayColumn} sx={sx}>
      {dangerouslySetInnerHtml ? (
        <StyledTypography dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        <StyledTypography data-testid={dataTestId}>{children}</StyledTypography>
      )}
    </StyledListItem>
  );
};

export default ListItem;
